export const textLabels = {
    // Inventory alert option descriptions
    savedFiltersBwicMonitor: 'Receive an email when securities matching your saved filter are on a BWIC.',
    savedFiltersInventory: 'Receive an email when security matches your saved filter has been added, updated, or marked as traded in the Dealer Inventory.',
    // Tooltips
    savedFiltersDefaultDailyTooltip: 'Alerts are sent at 7 AM EST every business day excluding holidays',
    savedFiltersDefaultInstantTooltip: 'Alerts are sent once the BWIC is posted with securities matching your saved filters.',
    savedFiltersInventoryInstantTooltip: 'Alerts are sent once security matches your saved filter has been added, updated, or marked as traded in the Dealer Inventory',

    // Portfolio alert option descriptions
    savedFiltersPortfolioBwicAlertDescription: "Receive email notifications once security from the portfolio is on a BWIC or Post-BWIC color is added.",
    savedFiltersPortfolioInventoryAlertDescription: "Receive email notifications once securities from the portfolio are added/updated/traded in the Dealer Inventory List.",

    // Portfolio alert tooltips
    savedFiltersPortfolioBwicInstantAlert: 'Alerts are sent once BWIC is posted/Post-BWIC color is added on the security from your portfolio.',
    saveFiltersPortfolioInventoryInstantsAlert: 'Alerts are sent once securities from your portfolio are added/updated/marked as traded in the Dealer Inventory.'
}